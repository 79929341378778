const _AUTH_URL = window.CONFIG?.KEYCLOAK_URL || process.env.VUE_APP_KEYCLOAK_URL
const AUTH_URL = _AUTH_URL.replace(/\/$/, ``)
const REALM = window.CONFIG?.KEYCLOAK_REALM || process.env.VUE_APP_KEYCLOAK_REALM
const CLIENT_ID = window.CONFIG?.KEYCLOAK_CLIENT_ID || process.env.VUE_APP_KEYCLOAK_CLIENT_ID
const ORIGIN_URL = window.location.origin
const BASE_URL = process.env.BASE_URL.replace(/\/$/, ``)
const REDIRECT_URL = BASE_URL ? `${ORIGIN_URL}${BASE_URL}` : ORIGIN_URL

export const oidcSettings = {
  "accessTokenExpiringNotificationTime": 3570,
  "authority": `${AUTH_URL}/realms/${REALM}`,
  // "authority": `${AUTH_URL}`,
  "clientId": CLIENT_ID,
  // "clientId": `2`,
  "redirectUri": `${REDIRECT_URL}/oidc-callback`,
  "popupRedirectUri": `${REDIRECT_URL}/oidc-popup-callback`,
  "responseType": "code",
  "scope": "openid",
  "automaticSilentRenew": false,
  "automaticSilentSignin": true
}
