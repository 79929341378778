import {id_field_desc} from "@/functions.js"
import {buildBooleanField, buildRelatedField} from "../fields"
import weekInput from './weekInput'
import weekendsInput from './weekendsInput'
import {cloneDeep, pickBy,} from "lodash"
import {keyCell} from "../../shared/index"
import {eventBus} from "../../index"

const root = '/pbx/services/v2'
const refRoot = `${root}/scheduleRef`
const SHCHREF_URLS = {
  list: `${refRoot}/list`,
  get: `${refRoot}/get`,
  add: `${refRoot}/create`,
  update: `${refRoot}/modify`,
  delete: `${refRoot}/delete`,

  // добавить/удалить правила
  add_rules: `${refRoot}/rulesAppend`,
  delete_rules: `${refRoot}/rulesDelete`
}

const template = obj => obj.caption || obj.number
const name = { label: 'Название', key: 'obj_name', cell: keyCell }

const clean_rule = o => pickBy(o, (v, k) => k != 'state')
const add_rule = o => {
  let ret = pickBy(o, (v, k) => k != 'state')
  for (const o of Object.keys(ret)) {
    if (ret[o]['fullRange'] === false) {
      delete ret[o]['fullRange']
    }
  }
  return ret
}

const ref_storage = {
  getters: {
    calced_options: () => (obj) => {
      let ret = {}
      let rules = cloneDeep(obj.rules)
      for (let r of rules) {
        r['state'] = 'old';
      }
      ret['rules'] = rules
      return ret
    }
  },
  actions: {
    add({ getters, commit, dispatch }, object) {
      const get_api = getters.has_api.add
      let add_data = getters.pre_data(object, 'add')
      add_data.rules = add_data.rules.map(clean_rule)
      return dispatch(
        'requestApi',
        {
          url: get_api,
          data: add_data,
          withFiles: getters.description.withFiles || false
        }, { root: true }
      ).then(
        d => {
          if (d.data.result == 'success') {
            const record = getters.post_data(d.data.payload, 'add')
            commit('update', record)
            commit('add_message', { type: 'success', message: `${getters.description.verbose} успешно создано` }, { root: true })
            eventBus.$emit(`added:${record.obj_type}`, record)
          }
          return d.data.payload
        })
      // ).then(data => dispatch(
      //   'requestApi',
      //   {
      //     url: SHCHREF_URLS.add_rules,
      //     data: {
      //       ...getters.unique_for,
      //       service_id: data.service_id
      //     },
      //     rules: rules
      //   },
      //   { root: true }
      // ))
    },
    update({ getters, commit, dispatch }, object) {
      const get_api = getters.has_api.update
      //debugger; // eslint-disable-line no-debugger
      let data_to_upd = getters.pre_data(object, 'update')
      let rules = data_to_upd.rules || []
      delete data_to_upd.rules
      const to_delete = rules.filter(r => r.state == 'delete').map(clean_rule)
      const to_add = rules.filter(r => r.state == 'new').map(add_rule)

      let req = dispatch(
        'requestApi',
        {
          url: get_api,
          data: data_to_upd
        }, { root: true }
      ).then(
        resp => {
          if (resp.data.result == 'success') {
            commit('add_message', { type: 'success', message: `${getters.description.verbose} успешно обновлено` }, { root: true })
          }
          // return dispatch('get', object.obj_id)
        }
      )
      if (to_delete.length) {
        req = req.then(
          () => {
            return dispatch('requestApi', { url: SHCHREF_URLS.delete_rules, data: { ...getters.unique_for, service_id: data_to_upd.service_id, rules: to_delete } }, { root: true })
          }
        )
      }
      if (to_add.length) {
        req = req.then(() => {
          return dispatch('requestApi', { url: SHCHREF_URLS.add_rules, data: { ...getters.unique_for, service_id: data_to_upd.service_id, rules: to_add } }, { root: true })
        })
      }
      req = req.then(() => dispatch('get', object.service_id))
      return req
    },
  }
}

const SHCHREF_DESC = {
  verbose: 'Расписание',
  plural: 'Расписания',
  create_button_text: 'Создать расписание',
  urls: SHCHREF_URLS,
  obj_type: 'shch_ref',
  obj_name: 'caption',
  obj_template: template,
  obj_key: 'service_id',
  unique_for: ['customer', 'entity'],
  storage: ref_storage,
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', required: true, isKey: true },
    rules: { label: 'Дни и время работы', comp: weekInput, required: true },
    number: { label: 'Номер', editable: false }
  },
  list_fields: [{ label: 'Название', key: 'obj_name', target: 'obj_name', cell: keyCell }, 'number'],
  new_fields: ['caption', 'rules'],
  view_fields: ['caption', 'number', 'rules'],
  edit_fields: ['caption', 'number', 'rules']
}

const URLS_SHCH = {
  list: `${root}/scheduleCheck/list`,
  // get: `${root}/scheduleCheck/get`,
  add: `${root}/scheduleCheck/create`,
  update: `${root}/scheduleCheck/modify`,
  delete: `${root}/scheduleCheck/delete`
}

const SHCH_DESC = {
  verbose: 'Расписание',
  plural: 'Расписания',
  create_button_text: 'Добавить график',
  no_search: true,
  obj_type: 'shch_check',
  obj_template: template,
  obj_key: 'service_id',
  urls: URLS_SHCH,
  unique_for: ['customer', 'entity'],
  fields: {
    serivce_id: id_field_desc,
    caption: { label: 'Название', required: true, isKey: true },
    number: 'Номер',
    scheduleRef: buildRelatedField('Расписание', SHCHREF_DESC.obj_type, { options: { addButton: true } }),
    isBypass: buildBooleanField('Использовать'),
    weekdays: {
      label: 'Дни и время работы',
      comp: weekInput
    },
    holidays: {
      label: 'Выходные и праздники',
      comp: weekendsInput
    }
  },
  edit_fields: ['caption', 'scheduleRef'],
  new_fields: ['caption', 'scheduleRef'],
  view_fields: ['caption', 'number', 'scheduleRef'],
  list_fields: [name, 'number', 'scheduleRef']
}
const test_shch = [
]

export { SHCH_DESC, SHCHREF_DESC, test_shch }